const setPush = (data) => {
    if (!data) return [];
    const errors = [];
    let count = 0;    
    for (const [value] of Object.entries(data)) {        
        for (const element of data[value]) {
            count++;           
            errors.push(<p key={count}>{element}</p>);
        }
    }
    return errors;
}
const HandelUndefinedError=(err)=>{
    if (err.toString().includes('Network Error')||err.toString().includes('ERR_TIMED_OUT')||err.toString().includes('ERR_NETWORK_CHANGED') ) {
        return "Network Error!"       
    }
}
const HandelError500=(err)=>{
    if (err.data) {
        return err.data;
    } else {
        return "Unexpected Error!";
    }
}
const HandelError400=(err)=>{
    
    if (err.data.errors) {
        return setPush(err.data.errors)
    } else {
        return ([`${err.statusText}-${err.data}`]);
    }
}
export const handleError = (err) => {
    if (err) {
        let error = [];
        switch (err.status) {
            case 402:
                window.location.href = "/user/payment";
                return null;
            case 403:
                error.push(`${err.statusText}-${err.data}`);
                break  
            case 404:
                error.push(`${err.statusText}-${err.data}`);
                break
            case 401:                
                window.location.href = `/`;
                break
            case 400:
                error= HandelError400(err)
                break
            case 415:
                error=  HandelError500(err)               
                break
            case 405:
                error.push('Method Not Allowed')
                break
            case 500:
                error= HandelError500(err)
                break
            case undefined:
                error= HandelUndefinedError(err)                
                break
            default:
                error.push("Unexpected Error!");
        }
        return error;
    }
};