import * as constants from './constant'

const INIT_STATE = {
    loading: false,
};

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {        
        case constants.Auth_Register:
        case constants.Auth_Verfiy:
            return { ...state,  loading: true };
        case constants.Auth_Register_FAILED:
        case constants.Auth_Verfiy_FAILED:
            return { ...state, loading: false, error: action.payload };       
        case constants.Auth_Register_SUCCESS:
            return { ...state, otpGenerate: action.payload.data, loading: false, error: null };      
        case constants.Auth_Verfiy_SUCCESS:
            return { ...state, token: action.payload, loading: false, error: null };      
        default:
            return { ...state };
    }
};

export default Reducer;
