import * as constants from './constant'

export const  AuthRegister= (body) => ({
    type: constants.Auth_Register,
    payload: { body },
});

export const AuthRegisterSuccess = (user) => ({
    type: constants.Auth_Register_SUCCESS,
    payload: user,
});

export const AuthRegisterFailed = (error) => ({
    type: constants.Auth_Register_FAILED,
    payload: error,
});


export const  AuthVerfiy= (body) => ({
    type: constants.Auth_Verfiy,
    payload: { body },
});

export const AuthVerfiySuccess = (user) => ({
    type: constants.Auth_Verfiy_SUCCESS,
    payload: user,
});

export const AuthVerfiyFailed = (error) => ({
    type: constants.Auth_Verfiy_FAILED,
    payload: error,
});
