import * as constants from './constant'

export const  Report_User_Add= (body) => ({
    type: constants.Report_User_Add,
    payload: { body },
});

export const Report_User_AddSuccess = (user) => ({
    type: constants.Report_User_Add_SUCCESS,
    payload: user,
});

export const Report_User_AddFailed = (error) => ({
    type: constants.Report_User_Add_FAILED,
    payload: error,
});

