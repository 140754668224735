import React from 'react';
import Loadable from 'react-loadable';
import { loading } from '../helpers/utils';

export const AuthLayout = Loadable({
    loader: () => import('../layouts/Auth'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

export const getLayout = () => {
    return AuthLayout;
}