import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import * as ServiceWorker from './serviceWorkerRegistration'
const root = createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </React.Fragment>
);
ServiceWorker.register();
reportWebVitals();