
import { Redirect, Route } from 'react-router-dom';
import { isUserAuthenticated } from '../helpers/utils';
// handle auth and authorization
export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {

            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/index', state: { from: props.location } }} />;
            }

            return <Component {...props} />;

        }}
    />
);